<template>
    <div class="container">
        <crud ref="crud" :options="options">
            <template slot="header">
                <el-form-item>
                    <el-input v-model="options.params.keyword" clearable placeholder="商户名字"/>
                </el-form-item>
                <el-form-item>
                    <tb-select ref="type" :options="typeOptions"
                               @change="val => this.options.params.type_id = val"/>
                </el-form-item>
                <el-form-item>
                    <el-select clearable v-model="options.params.show" placeholder="显示筛选">
                        <el-option :key="1" :value="1" label="隐藏"></el-option>
                        <el-option :key="2" :value="2" label="显示"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button @click="$refs.crud.getLists()" icon="el-icon-search">查找</el-button>
                </el-form-item>
            </template>
        </crud>
    </div>
</template>

<script>
export default {
    name: "List",
    data: function () {
        return {
            options: {
                addBtn: true,
                editBtn: true,
                delBtn: true,
                pager: true,
                addUrl: 'league.Merchant.add',
                addPerm: 'league_Merchant_add',
                editUrl: 'league.Merchant.edit',
                editPerm: 'league_Merchant_edit',
                delUrl: 'league.Merchant.delete',
                delPerm: 'league_Merchant_delete',
                listUrl: 'league.Merchant.page',
                formLabelWidth: '100px',
                params: {
                    keyword: null,
                    show: null,
                    type_id: null
                },
                columns: [
                    {
                        prop: "site_id",
                        label: "所在站点",
                        type: 'select',
                        dicUrl: 'league.Site.list',
                        dicData: [],
                        dicProps: {label: 'name', value: 'id'},
                        required: false,
                        field: false,
                        formatter: row => {
                            return row.site ? row.site.name : ''
                        }
                    },
                    {
                        prop: "merchant_type_id",
                        label: "商户类型",
                        type: 'select',
                        dicUrl: 'league.MerchantType.list',
                        dicData: [],
                        dicProps: {label: 'name', value: 'id'},
                        required: true,
                        formatter: row => {
                            return row.type ? row.type.name : ''
                        }
                    },
                    {prop: "name", label: "商户名称", required: true},
                    {
                        prop: "cover", label: "商户封面", required: true, type: 'image', width: 72,
                        html: ({row}) => {
                            return row.cover ? `<a target="_blank" href="${row.cover}"><img class="list-user-avatar" src="${row.cover}" alt="加载失败"/></a>` : null
                        }
                    },
                    {prop: "desc", label: "商户描述"},
                    {prop: "address", label: "商户地址", required: true},
                    {prop: "mobile", label: "联系电话", required: true},
                    {
                        prop: "qrcode", label: "二维码", required: true, type: 'image', width: 72,
                        html: ({row}) => {
                            return row.qrcode ? `<a target="_blank" href="${row.qrcode}"><img class="list-user-avatar" src="${row.qrcode}" alt="加载失败"/></a>` : null
                        }
                    },
                    {prop: "business", label: "营业时间"},
                    {prop: "share_count", label: "总分享次数", type: "number", required: true},
                    {prop: "member_share", label: "用户分享次数", type: "number", required: true, value: 0},
                    {prop: "price", label: "价格(元)", type: "number", required: true},
                    {
                        prop: "show",
                        label: "是否显示",
                        type: "radio",
                        dicData: [{label: '显示', value: 1}, {label: '隐藏', value: 0}],
                        value: 1,
                        formatter: row => {
                            return row.show === 1 ? '显示' : '隐藏'
                        }
                    },
                    {prop: "sort", label: "排序", type: "number", required: true, value: 0},
                    {
                        prop: "status",
                        label: "状态",
                        type: "radio",
                        required: true,
                        dicData: [{label: '进行中', value: 1}, {label: '预热中', value: 2}, {label: '已完结', value: 3}],
                        value: 1,
                        formatter: row => {
                            const status = ['进行中', '预热中', '已完结']
                            return status[row.status - 1]
                        }
                    },
                    {prop: "remark", label: "备注",}
                ],
            },
            typeOptions: {
                listUrl: 'league.MerchantType.list',
                filter: (item, value) => {
                    return !value || item.name.includes(value)
                },
                label: "item.name",
                placeholder: '类型筛选'
            },

        }
    },
    activated: function () {
        this.$refs.type.getLists()
    },
    methods: {}
}
</script>

<style scoped>

</style>
